.why-choose-us-grid {
  padding: 2rem;
  background-color: #000000;
  padding-top: 50px;
  padding-bottom: 50px;
}

.why-choose-us-heading {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 2rem;
  color: #fff;
}

.why-choose-us-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.why-choose-us-item {
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(255, 254, 254, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.why-choose-us-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(100, 95, 95, 0.1);
}

.why-choose-us-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 1rem;
  color: #4A90E2;
  transition: color 0.3s ease;
}

.why-choose-us-item:hover .why-choose-us-icon {
  color: #FF8C00; /* Change icon color on hover */
}

.why-choose-us-subheading {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  color: #333;
  transition: color 0.3s ease;
}

.why-choose-us-item:hover .why-choose-us-subheading {
  color: #FF8C00; /* Change text color on hover */
}

.why-choose-us-text {
  font-size: 1rem;
  color: #555;
  transition: color 0.3s ease;
}

.why-choose-us-item:hover .why-choose-us-text {
  color: #333; /* Slightly darken text on hover */
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .why-choose-us-heading {
    font-size: 1.75rem;
    margin-bottom: 1.5rem;
  }

  .why-choose-us-subheading {
    font-size: 1.15rem;
  }

  .why-choose-us-text {
    font-size: 0.95rem;
  }

  .why-choose-us-item {
    padding: 1rem;
  }

  .why-choose-us-icon {
    width: 40px;
    height: 40px;
  }
}
