/* Contact.css */

.contact {
    background-color: #f9f9f9;
    color: #333333;
    padding: 80px 0;
  }
  
  .contact .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .contact h2 {
    font-size: 36px;
    color: #1e0a3c;
    margin-bottom: 40px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1.5px;
  }
  
  .contact-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 40px;
  }
  
  .contact-info {
    flex: 1;
    min-width: 300px;
  }
  
  .contact-info h3 {
    font-size: 24px;
    color: #1e0a3c;
    margin-bottom: 20px;
  }
  
  .contact-description {
    font-size: 16px;
    color: #666666;
    margin-bottom: 30px;
  }
  
  .contact-details {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .contact-detail {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  
  .contact-detail i {
    font-size: 24px;
    color: #e74c3c;
  }
  
  .contact-detail p {
    font-size: 16px;
    color: #333333;
  }
  
  .contact-form {
    flex: 1;
    min-width: 300px;
  }
  
  .contact-form form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .contact .form-group {
    display: flex;
    flex-direction: column;
  }
  
  .contact label {
    font-size: 18px;
    color: #1e0a3c;
    margin-bottom: 8px;
    font-weight: 500;
  }
  
  .contact input[type="text"],
  .contact input[type="email"],
  .contact textarea {
    padding: 12px 15px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    font-size: 16px;
    background-color: #f4f4f4;
    transition: border-color 0.3s ease;
  }
  
  .contact input[type="text"]:focus,
  .contact input[type="email"]:focus,
  .contact textarea:focus {
    border-color: #2c3e50;
    outline: none;
  }
  
  .contact textarea {
    resize: vertical;
    min-height: 150px;
  }
  
  .contact .btn {
    background-color: #1e0a3c;
    color: #ffffff;
    padding: 12px 25px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
    transition: background-color 0.3s, transform 0.2s;
  }
  
  .contact .btn:hover {
    background-color: #34495e;
    transform: translateY(-2px);
  }
  