/* Footer.css */

footer {
    background-color: #2c3e50; /* Dark blue/gray background */
    color: #ffffff; /* White text color */
    padding: 20px 0; /* Padding for top and bottom */
    text-align: center; /* Center align text */
  }
  
  footer .container {
    max-width: 1200px; /* Container width */
    margin: 0 auto; /* Center the container */
    padding: 0 15px; /* Padding for left and right */
  }
  
  footer p {
    margin: 0; /* Remove default margin */
    font-size: 14px; /* Font size for the text */
  }
  