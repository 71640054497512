/* Hero.css */

.hero {
    background-image: url('../../Assets/images/Heroimage.png'); /* Add your background image */
    background-size: cover; /* Cover the entire section */
    background-position: center; /* Center the background image */
    color: #ffffff; /* White text color */
    padding: 10px 0; /* Padding for top and bottom */
    text-align: center; /* Center align text */
    position: relative; /* For overlay */
    display: flex; /* Enable flexbox */
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
    height: 50vh; /* Half viewport height */
  }
  
  .hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3); /* Dark overlay */
    z-index: 1;
  }
  
  .hero .container {
    position: relative;
    z-index: 2; /* Bring content above the overlay */
    max-width: 1200px; /* Container width */
    margin: 0 auto; /* Center the container */
    padding: 0 15px; /* Padding for left and right */
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the text content horizontally */
  }
  
  .hero h2 {
    font-size: 48px; /* Large font size for the heading */
    margin-bottom: 20px; /* Space below the heading */
  }
  
  .hero p {
    font-size: 20px; /* Font size for the paragraph */
    margin-bottom: 40px; /* Space below the paragraph */
  }
  
  .hero .btn {
    background-color: #e74c3c; /* Red background color */
    color: #ffffff; /* White text */
    padding: 15px 30px; /* Padding inside button */
    border: none; /* Remove default border */
    border-radius: 4px; /* Rounded corners */
    font-size: 18px; /* Font size for button text */
    text-transform: uppercase; /* Uppercase text */
    text-decoration: none; /* Remove underline */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s; /* Smooth background color transition */
  }
  
  .hero .btn:hover {
    background-color: #c0392b; /* Slightly darker red on hover */
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .hero {
      height: 60vh; /* Adjust height for tablets */
    }
  
    .hero h2 {
      font-size: 36px; /* Smaller font size for the heading on tablets */
      margin-bottom: 15px; /* Adjusted space below the heading */
    }
  
    .hero p {
      font-size: 18px; /* Adjusted font size for the paragraph */
      margin-bottom: 30px; /* Adjusted space below the paragraph */
    }
  
    .hero .btn {
      padding: 12px 25px; /* Adjust padding inside button */
      font-size: 16px; /* Adjust font size for button text */
    }
  }
  
  @media (max-width: 480px) {
    .hero {
      height: 70vh; /* Adjust height for mobile screens */
      padding: 20px 0; /* Increase padding for better spacing on mobile */
    }
  
    .hero h2 {
      font-size: 28px; /* Smaller font size for the heading on mobile screens */
      margin-bottom: 10px; /* Adjusted space below the heading */
    }
  
    .hero p {
      font-size: 16px; /* Smaller font size for the paragraph on mobile */
      margin-bottom: 20px; /* Adjusted space below the paragraph */
    }
  
    .hero .btn {
      padding: 10px 20px; /* Adjust padding inside button */
      font-size: 14px; /* Smaller font size for button text */
    }
  }
  