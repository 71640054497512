.services {
  padding: 60px 0;
  background-color: #f9f9f9;
}

.services .container {
  flex-direction: column;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.section-title {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #333;
}

.service-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  justify-content: center;
}

.service-card {
  background: white;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.service-icon {
  width: 40px;
  height: 40px;
  color: #4a90e2;
  margin-bottom: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.service-card h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #333;
}

.service-card p {
  font-size: 1rem;
  line-height: 1.6;
  color: #666;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f9f9f9;
  padding: 30px;
  max-width: 700px;
  width: 90%;
  display: flex;
  flex-direction: column; /* Make modal content a flex container */
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  font-family: 'Arial', sans-serif;
  overflow-y: auto;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;
}

.modal-content {
  flex-grow: 1; /* Allow content to grow and push footer to the bottom */
}

.modal-content h2 {
  margin-top: 0;
  font-size: 24px;
  color: #000;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.modal-content p {
  font-size: 16px;
  color: #333;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: justify;
}

.react-tabs__tab-list {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-around;
  list-style-type: none;
  margin-bottom: 20px;
  border-bottom: 2px solid #007bff;
}

.react-tabs__tab {
  padding: 10px;
  cursor: pointer;
  color: #007bff;
  font-weight: bold;
  height: 60px;
}

.react-tabs__tab--selected {
  border-bottom: 2px solid #ffffff;
}

.react-tabs__tab-panel {
  margin-bottom: 50px;
  width: 90%;
  height:  100px;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #e0e0e0; /* Optional: add a top border for separation */
}

.cta-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cta-button:hover {
  background-color: #0056b3;
}

.cta-button.primary {
  background-color: #28a745;
}

.cta-button.primary:hover {
  background-color: #218838;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .section-title {
    font-size: 2rem;
    margin-bottom: 30px;
  }

  .service-cards {
    grid-template-columns: 1fr; /* Stack cards vertically on mobile */
    gap: 15px;
  }

  .service-card {
    padding: 20px;
  }

  .service-card h3 {
    font-size: 1.25rem;
  }

  .service-card p {
    font-size: 0.9rem;
  }

  .modal {
    max-width: 90%; /* Make sure the modal fits within mobile screens */
    padding: 20px;
    height: 80vh;
  }

  .modal-content h2 {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .modal-content{
    flex-grow: 1;
    overflow-y: auto;
  }

  .modal-content p {
    font-size: 14px;
  }

  .react-tabs__tab-list {
    flex-direction: column; /* Stack tabs vertically on mobile */
    align-items: flex-start;
  }

  .react-tabs__tab {
    width: 100%; /* Make tabs full width */
    text-align: left;
    padding: 15px 10px;
  }

  .modal-footer {
    flex-direction: column;
    align-items: center;
    margin-top: auto; /* Ensure footer stays at the bottom */
  }

  .cta-button {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }

  .cta-button.primary {
    width: 100%;
  }
}
