.header {
    background-color: #ffffff; /* White background */
    padding: 10px 0; /* Padding for top and bottom */
    border-bottom: 1px solid #eaeaea; /* Border for the bottom */
    position: fixed; /* Fix the header at the top */
    top: 0;
    left: 0;
    width: 100%; /* Full width */
    z-index: 1000; /* Ensure it stays on top of other elements */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add slight shadow for depth */
  }
  
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px; /* Container width */
    margin: 0 auto; /* Center the container */
    padding: 0 15px; /* Padding for left and right */
  }
  
  .logo {
    display: flex;
    align-items: center;
  }
  
  .logo img {
    height: 40px; /* Adjust the height as needed */
    margin-right: 10px; /* Space between the logo and the text */
  }
  
  .logo h1 {
    font-size: 24px;
    color: #1e0a3c; /* Dark text color */
    margin: 0;
    font-weight: 700;
  }
  
  .nav ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .nav ul li {
    margin-right: 20px; /* Space between links */
  }
  
  .nav ul li a {
    text-decoration: none;
    color: #1e0a3c; /* Dark text color */
    font-weight: 500;
    font-size: 14px;
  }
  
  .nav ul li a:hover {
    color: #000000; /* Hover color for links */
  }
  
  .actions {
    display: flex;
    align-items: center;
  }
  
  .contact-btn {
    background-color: #1e0a3c;
    color: #ffffff;
    padding: 5px 15px;
    border: none;
    border-radius: 25px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
  }
  
  .contact-btn:hover {
    background-color: #000000; /* Darken button on hover */
  }
  
  /* Add padding to the top of the body content to account for the fixed header */
  body {
    padding-top: 60px; /* Adjust this value based on the height of your header */
  }
  
  /* Mobile responsiveness */
  @media (max-width: 768px) {
    .logo h1 {
      display: none; /* Hide the company name on smaller screens */
    }
  
    .nav ul {
      flex: 1; /* Make the navigation take up available space */
      align-items: center;
      justify-content: flex-end; /* Align navigation items to the right */
    }
  
    .nav ul li {
      margin-right: 10px; /* Reduce space between links */
    }
  
    .nav ul li:last-child {
      margin-right: 0; /* Remove right margin from the last item */
    }
  
    .actions {
      margin-left: 5px; /* Reduce space between the navigation and the contact button */
    }
  
    .contact-btn {
      padding: 6px 15px; /* Adjust padding for better fit */
      font-size: 14px; /* Keep font size for better readability */
    }
  
    .container {
      padding: 0 5px; /* Further reduce padding to make better use of space on smaller screens */
    }
  }
  